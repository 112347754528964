import React from "react"
import LogoBottom from "../../static/images/DAIQUIRI_logo_web_gray.svg"

const Footer = () => {
  return (
    <footer className="footer footer-light text-center d-md-flex flex-row justify-content-between mx-lg-12 mb-lg-6 mx-md-10 mb-5">
      <LogoBottom
        style={{
          height: 30,
        }}
      />
      <div className="fs-sm text-muted align-self-center pt-2 py-md-0">
        Daiquiri Technology &copy; 2020
      </div>
    </footer>
  )
}

export default Footer
